import React, { Component, useEffect, useState } from "react"

import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { NavLink, useNavigate } from "react-router-dom";
import { act } from "react-dom/test-utils";
const items = [
  // More pages got added as part of the workshop.
  { type: 'link', text: 'Home page', href: '/' },
  { type: 'link', text: 'Device Registration', href: '/device-registration' },
  { type: 'link', text: 'Connected Devices', href: '/device-dashboard' },
  { type: 'link', text: 'Metrics dashboard', href: '/metrics' },
];

const navLinkItems = [
  { title: 'Home page', path: '/' },
  { title: 'Device Registration', path: '/device-registration' },
  { title: 'Connected Devices', path: '/device-dashboard' },
  { title: 'Metrics dashboard', path: '/metrics' },

]



export default function Navigation({currentHref}) {
    const [activeHref, setActiveHref] = useState(currentHref);
    let navigate = useNavigate()
    useEffect(()=>{
      navigate(activeHref)
    },[activeHref])
  return (
      <SideNavigation
      
            onFollow={event => {       
                 if (!event.detail.external) {      
                        event.preventDefault();          
                        setActiveHref(event.detail.href);       
                        console.log("can click now")
                       
                     }      
                    
                    }
                    
                  }
                  
            // onChange={
            //     navigate(activeHref)
            // }
        activeHref={activeHref}
        header={{ href: '/', text: 'Service' }}
        items={
          // navLinkItems.map((link)=>{
          //   // console.log(link)
          //   // <NavLink to={item.path} activeClassName="active">
          //   //   {/* {item.title} */}
          //   // </NavLink>
          // })
        items
        }
      />
  
  );
}
