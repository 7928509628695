import React, { Component, useEffect, useState } from "react"


// import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Container1 from '@cloudscape-design/components/container'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Wizard} from "@awsui/components-react"
import { AppLayout, ContentLayout, Header, SpaceBetween, TopNavigation, Button, Input, FormField, ColumnLayout, Link, Modal, Box} from "@cloudscape-design/components";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation.js";
// import CustomHeader from "../components/CustomHeader.js";
import { Auth } from "aws-amplify";

// import Outfit from '../fonts/static/Outfit-Regular.ttf'
// import Header from "@amzn/awsui-components-react/polaris/header";
// import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// import AWSButton from "@amzn/awsui-components-react/polaris/button";

// import Outfit from '../fonts/static/Outfit-Regular.ttf'

// import Header from "@amzn/awsui-components-react/polaris/header";
// import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// import AWSButton from "@amzn/awsui-components-react/polaris/button";



export default function DeviceRegistration(){
  const navigate = useNavigate(); 
  const [    activeStepIndex,    setActiveStepIndex  ] = React.useState(0);
  const [currentMessage, setMessage] = useState("Device Registration Complete")
  const [currentUserEmail, setUserEmail] = useState("")
  const [modalVisible, setVisible] = useState(false);
  const [disabledFinish, setDisabledFinish] = useState(false)
  const [DeviceInformation, setDeviceInformation] = useState({
      snowname:"",
      nodeid:"",
      instanceid:"",
      jobname:""

  })

const validateForm = () => {
  const nodeid_pattern = /^mi-[a-z0-9]+$/ 
  const alphanums_pattern = /^[A-Za-z0-9]+$/
  const instanceid_pattern = /^s\.i-[A-Za-z0-9]+$/
  // checks if inputs are empty & meet the requirements
if (!nodeid_pattern.test(DeviceInformation.nodeid)) {
    alert('Node ID must match format mi-xxxxxxxxx')
} else if(!DeviceInformation.nodeid === nodeid_pattern) {
  alert("Node ID is no valid")
}
else if (!alphanums_pattern.test(DeviceInformation.snowname)) {
  alert('Snow Device Name can only contain numbers and letters')
  
}
else if (!instanceid_pattern.test(DeviceInformation.instanceid)) {
    alert('Instance ID must match format s.i-xxxxxx')
   
  }
  else if (!alphanums_pattern.test(DeviceInformation.jobname)) {
    //console.log(DeviceInformation.jobname)
    alert('Job Name Name must not be empty and only contain numbers and letters')
    
}
else{
  async function fetchAccessToken() {
    const session = await Auth.currentSession();
        const currAuthUser = await Auth.currentAuthenticatedUser()
     
        const id_token = currAuthUser.signInUserSession.getIdToken().getJwtToken();
        const jwt_token = session.getAccessToken().getJwtToken();
        fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/appenddeviceinfo', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': id_token,
            "email_address": currentUserEmail
          },
          body: JSON.stringify(DeviceInformation)
        })
          .then(response => response.json())
          .then(data => {
            //console.log(data)
            setTimeout(() => {
             setMessage(data.body)
             setVisible(true)
             
            }, 200);
            
            
           
          })
          .catch(error => {
            // Handle any errors
            console.error('Error:', error);
          });

  }
  fetchAccessToken()

//   fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/appenddeviceinfo', {
//   method: 'PUT',
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': localStorage.getItem("usertoken"),
//     "email_address": currentUserEmail
//   },
//   body: JSON.stringify(DeviceInformation)
// })
//   .then(response => response.json())
//   .then(data => {
//     setTimeout(() => {
//      setVisible(true)
     
//     }, 200);
    
    
   
//   })
//   .catch(error => {
//     // Handle any errors
//     console.error('Error:', error);
//   });


}



}

  async function getUserEmail() {
    const user = await Auth.currentAuthenticatedUser();
    setUserEmail(user.attributes.email)

  }

  useEffect(()=>{
    getUserEmail()
    
  },[])
  const handleSnowDeviceNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, snowname: e.detail.value})  };
    
  const handleNodeIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, nodeid: e.detail.value})
  };

  const handleInstanceIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, instanceid: e.detail.value})
  };

  const handleJobNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, jobname: e.detail.value})
  };
  return(
    <Container maxWidth={"false"} disableGutters >

    <Modal
      onDismiss={() => {setVisible(false); navigate("/device-dashboard")}}
      visible={modalVisible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link">Cancel</Button>
            <Button variant="primary" onClick={()=>{ navigate("/device-dashboard")}}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header=""
    >
      {currentMessage}
    </Modal>
  


<AppLayout
        headerSelector="#top-nav"
        navigation={<Navigation currentHref={"/device-registration"}/>}
       content={
          <ContentLayout header={<Header variant="h1">Set up snow device</Header>}>
 <SpaceBetween direction="vertical" size="xs">
 
 <Container/> <Container/> <Container/> <Container/> <Container/>
<Wizard
  i18nStrings={{
    stepNumberLabel: stepNumber =>
      `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
      `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step, stepNumber) =>
      `Skip to ${step.title}`,
    navigationAriaLabel: "Steps",
    
    previousButton: "Previous",
    nextButton: "Next",
    submitButton: "Finish",
    optional: "optional"
  }}
  
  onNavigate={({ detail }) =>
    setActiveStepIndex(detail.requestedStepIndex)
  }
  onSubmit={()=>{ 
    validateForm()
  }}
  activeStepIndex={activeStepIndex}
  allowSkipTo
  steps={[
    // {
    //   title: "Add your SSH Key",
    //   info: <Link variant="info">Info</Link>,
    //   description:
    //    "Snowbird description here",
    //   content: (
    //     <Container
    //       header={
    //         <Header variant="h2">
    //           Form container header
    //         </Header>
    //       }
    //     >
    //       <SpaceBetween direction="vertical" size="l">
    //         <FormField label="SSH Key">
    //           <Input />
    //         </FormField>

    //       </SpaceBetween>
    //     </Container>
    //   )
    // },
    {
      title: "Device information",
      content: (
        <Container
          header={
            <Header variant="h2">
              Form container header
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Snow Device Name - optional">
            <Input onChange={(e) => handleSnowDeviceNameChange(e)} value={DeviceInformation.snowname}/>
            </FormField>
            <FormField label="Node ID">
            <Input onChange={(e) => handleNodeIDChange(e)} value={DeviceInformation.nodeid}/>
            </FormField>
            <FormField label="Instance ID">
              <Input onChange={(e) => handleInstanceIDChange(e)} value={DeviceInformation.instanceid}/>
            </FormField>
            <FormField label="Job name">
            <Input onChange={(e) => handleJobNameChange(e)} value={DeviceInformation.jobname}/>
            </FormField>
          </SpaceBetween>
        </Container>
      ),
      isOptional: false
    },

    {
      title: "Job Summary",
      content: (
        <SpaceBetween size="xs">
          <Header
            variant="h3"
            actions={
              <Button
                onClick={() => setActiveStepIndex(0)}
              >
                Edit
              </Button>
            }
          >
            Step 1: Instance type
          </Header>
          <Container
            header={
              <Header variant="h2">
                Container title
              </Header>
            }
          >
            <ColumnLayout
              columns={2}
              variant="text-grid"
            >
              <div>
                <Box variant="awsui-key-label">
                  Snow Device Name
                </Box>
                {DeviceInformation.snowname}
              </div>
              <div>
                <Box variant="awsui-key-label">
                  Node ID
                </Box>
                {DeviceInformation.nodeid}
              </div>
              <div>
                <Box variant="awsui-key-label">
                Instance ID
                </Box>
                {DeviceInformation.instanceid}
              </div>
              <div>
                <Box variant="awsui-key-label">
               Job name
                </Box>
                {DeviceInformation.jobname}
              </div>
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      )
    }
  ]}
/>

</SpaceBetween>

            
          
          </ContentLayout>
        }
        
        />



    </Container>
  )
}
