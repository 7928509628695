import React, { Component, useEffect, useState } from "react"


// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Container1 from '@cloudscape-design/components/container'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppLayout, ContentLayout, Header, SpaceBetween, TopNavigation, Button } from "@cloudscape-design/components";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation.js";
// import Outfit from '../fonts/static/Outfit-Regular.ttf'
// import Header from "@amzn/awsui-components-react/polaris/header";
// import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// import AWSButton from "@amzn/awsui-components-react/polaris/button";

import '../styles.css';
import { Link } from "react-router-dom";
// import CustomHeader from "../components/CustomHeader.js";

export default function DevicesList(){
  const navigate = useNavigate();  
  return(
    <Container maxWidth={"false"} disableGutters >
        {/* <Header id="top-nav"
          variant="h1"

        >
          Snowbird
        </Header> */}
            {/* <CustomHeader/> */}
        <AppLayout
        headerSelector="#top-nav"
        navigation={<Navigation currentHref={"/"}/>}
       content={
          <ContentLayout header={<Header variant="h1">Dashboard</Header>}>
           <Container1  >
            <SpaceBetween direction="vertical" size="xs">
            <Box sx={{maxWidth: '20%'}}>
              Ready to get started?

            </Box>
           
            <Button size={"large"} onClick={()=>{navigate("/device-registration")}}>
            
            Click here
              </Button>
            </SpaceBetween>

           </Container1>
           

           
            
          
          </ContentLayout>
        }
        
        />
     
    </Container>
  )
}