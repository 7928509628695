import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";

export default ({deviceList, setSelectedDevice}) => {
  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([]);
   const [listitems, setListItems] = React.useState()
   const [numPages, setNumPages] = React.useState(1)
   const [filteringText, setFilteredText] = React.useState("")
   React.useEffect(()=>{
      setSelectedDevice(selectedItems)

   },[selectedItems])
   const handleFilteringText = (e) => {
    //console.log(e.detail.filteringText)
    //setFilteredText(...filteringText,e.detail.value);
    setFilteredText(e.detail.filteringText)  
   }
  React.useEffect(()=>{
    var arr = []
    deviceList.map((obj)=>{
     //console.log(obj.Value) //Object { snowdevicename: "snowname", instanceid: "s.i-12po3jk12", nodeid: "mi-dal123f", jobname: "jobnameceldyn" } DeviceTable.js:30
      
      arr.push({
          name: obj.Value.snowdevicename,
          instanceid: obj.Value.instanceid,
          idnode: obj.Value.nodeid,
          jname: obj.Value.jobname,
          size: "Small"
                //     name: "Device Name Here",
      //     alt: "First",
      //     description: "This is the first item",
      //     type: "1A",
      //     size: "Small"
      })
      //console.log(arr)
       
    })

    setListItems(arr)
    setNumPages(Math.ceil(arr.size / 5))



  },[deviceList])
  // const populateCustomList = (deviceList) => {
    

  // }
  return (
    <Table
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems)
        //console.log(detail.selectedItems[0])
      }
        
      }
      selectedItems={selectedItems}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${
            selectedItems.length === 1 ? "item" : "items"
          } selected`,
        itemSelectionLabel: ({ selectedItems }, item) =>
          item.name
      }}
    
      columnDefinitions={[
        {
          id: "snow_device_name",
          header: "Snow Device Name",
          cell: e => e.name,
          sortingField: "name",
          isRowHeader: true
        },
        {
          id: "instance_id",
          header: "Instance ID",
          cell: e => e.instanceid,
          sortingField: "instanceid"
        },
        { id: "jobname", header: "Job Name ", cell: e => e.jname },
        {
          id: "nodeid",
          header: "Node ID",
          cell: e => e.idnode
        }
      ]}
      columnDisplay={[
        { id: "snow_device_name", visible: true },
        { id: "instance_id", visible: true },
        { id: "nodeid", visible: true },
        { id: "jobname", visible: true }
      ]}

      items={listitems}
      // items={()=>{
      // //   deviceList.map(function (snowdevicename, instanceid, nodeid, jobname) {
      // //     console.log(snowdevicename)
      // //     // return {
      // //     //   name: snowdevicename,
      // //     //   instanceid: instanceid,
      // //     //   description: "This is the first item",
      // //     //   type: "1A",
      // //     //   size: "Small"       
      // //     // }
      // // });
      // }}

      loadingText="Loading resources"
      selectionType="single" //radio button, turn off multi select
      trackBy="name"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      }
      filter={
        <TextFilter
          filteringPlaceholder="Find resources"
          filteringText={filteringText}
          onChange={(e) => handleFilteringText(e)}
        />
      }
      header={
        <Header
          counter={
            deviceList.length
              ? "(" + selectedItems.length + "/"+deviceList.length+")"
              : "(10)"
          }
        >
         Snow Device List
        </Header>
      }
      pagination={
        <Pagination currentPageIndex={1} pagesCount={numPages} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: [
              { id: "variable", visible: true },
              { id: "value", visible: true },
              { id: "type", visible: true },
              { id: "description", visible: true }
            ]
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" }
            ]
          }}
          wrapLinesPreference={{}}
          stripedRowsPreference={{}}
          contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "variable",
                label: "Variable name",
                alwaysVisible: true
              },
              { id: "value", label: "Text value" },
              { id: "type", label: "Type" },
              { id: "description", label: "Description" }
            ]
          }}
          stickyColumnsPreference={{
            firstColumns: {
              title: "Stick first column(s)",
              description:
                "Keep the first column(s) visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "First column", value: 1 },
                { label: "First two columns", value: 2 }
              ]
            },
            lastColumns: {
              title: "Stick last column",
              description:
                "Keep the last column visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "Last column", value: 1 }
              ]
            }
          }}
        />
      }
    />
  );
}