import logo from './logo.svg';
import './App.css';
import {
  Browser,
  Switch,
  Route,
  Routes,
  Link,
  BrowserRouter
} from "react-router-dom";
import DeviceRegistration from './pages/DeviceRegistration';
import DevicesList from './pages/DevicesList'
import Dashboard from './pages/Dashboard'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth, Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import CustomHeader from './components/CustomHeader';
import Metrics from './pages/Metrics';
// import Login from './pages/Login'
import { useEffect, useState } from 'react';
import { RequireAuth } from './components/RequireAuth';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);


function App() {
  const getToken = () => {
    const tok = window.localStorage.getItem('authtoken')
    //console.log(tok)
    return tok


  }

  const [token, setToken] = useState(getToken())
  const [jwttoken, setJWTToken] = useState(null);
  const [userLoggedIn, setUserLog] = useState(false)

  // useEffect(()=>{
  //   setToken(token)

  // },[token])
  useEffect(()=>{
    // let jwt_token = await Auth.currentSession().then(res=>{
    //   let jwttoken = res.getAccessToken().getJwtToken()
    //   localStorage.setItem("usertoken", jwttoken)
    //   //console.log(jwttoken)

    // })

    async function fetchAccessToken() {
      try {
        const session = await Auth.currentSession();
        const currAuthUser = await Auth.currentAuthenticatedUser()
     
        const id_token = currAuthUser.signInUserSession.getIdToken().getJwtToken();
        const jwt_token = session.getAccessToken().getJwtToken();
        setJWTToken(jwt_token);
       
        console.log(userLoggedIn)
        const params = {
          "jwt_token": jwt_token
        }
             fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/execute-grafana-login', {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': id_token
                },
                body: JSON.stringify(params)
              })
                .then(response => response.json())
                .then(data => {
                  //console.log(data)
                  
                  
                
                })
                .catch(error => {
                  // Handle any errors
                  console.error('Error:', error);
                  //console.log(JSON.stringify(localStorage.getItem("usertoken")))
                });
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    }
    window.localStorage.setItem("userloggedin", false)
  
    const userlog = window.localStorage.getItem("userloggedin")
    //console.log(!userlog)
    if(!window.localStorage.getItem("userloggedin") === false)
    {
      fetchAccessToken()
      window.localStorage.setItem("userloggedin", true)
      setUserLog(true)
    }
     
    //   console.log(jwt_token)

    
    // console.log(jwt_token)
    


  },[])

  const goToDashboard = () => {
    //console.log("going to dash")

    return (
      <div>
      <CustomHeader/>
      <Dashboard />
            </div>
    )

  }
  /*When called it goes to login and returns */
  // const goToLogin = () => {

  //   return <Login setToken={setToken} />
  // }
  const goToDeviceRegistration = () => {

    return (
      <div>
<CustomHeader/>
<DeviceRegistration />
      </div>
   
    )
  }
  const goToDevicesList = () => {
    
    return(
      <div>
        <CustomHeader/>
        <DevicesList />
      </div>
      )
  }

  const goToMetrics = () => {
    
    return (
    <div>
     <CustomHeader/>  
 <Metrics />
    </div>
   )
  }
  return (
    // <div >
    // <Heading level={1}>Hello {user.username}</Heading>
    // <Button onClick={signOut}>Sign out</Button>
    // <h2>Amplify Todos</h2>
    // </div>
    
    <BrowserRouter>
    <div>
    <CustomHeader/>
    </div>


    {/* <Routes>
    <Route path="/" element={token ? goToDashboard() : goToLogin()}/>
    <Route path="/login" element={<Login setToken={setToken} />} />
    <Route path="/device-registration" element={token ? goToDeviceRegistration() : goToLogin()}/>
    <Route path="/device-dashboard" element={token ? goToDevicesList() : goToLogin()}/>
    <Route path="/metrics" element={token ? goToMetrics() : goToLogin()}/>
    </Routes> */}

    <Routes>
    <Route path="/" element={ <Dashboard/> }/>
    {/* <Route path="/login" element={<Login/>} /> */}
    <Route path="/device-registration" element={ <DeviceRegistration/> }/>
    <Route path="/device-dashboard" element={ <DevicesList/> }/>
    <Route path="/metrics" element={ <Metrics/> }/>
    </Routes>
 
    </BrowserRouter>
    

  );
}

export default withAuthenticator(App);