import React, { Component, useEffect, useState } from "react"

// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Container1 from '@cloudscape-design/components/container'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Wizard} from "@awsui/components-react"
import { AppLayout, ContentLayout, Header, SpaceBetween, TopNavigation, Button, Input, FormField, ColumnLayout, Link } from "@cloudscape-design/components";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import Navigation from "../components/Navigation.js";
import DeviceTable from "../components/DeviceTable.js";
// import CustomHeader from "../components/CustomHeader.js";


// import Outfit from '../fonts/static/Outfit-Regular.ttf'
// import Header from "@amzn/awsui-components-react/polaris/header";
// import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// import AWSButton from "@amzn/awsui-components-react/polaris/button";

// import Outfit from '../fonts/static/Outfit-Regular.ttf'

// import Header from "@amzn/awsui-components-react/polaris/header";
// import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
// import AWSButton from "@amzn/awsui-components-react/polaris/button";



export default function Metrics(){
  const navigate = useNavigate(); 
  const [    activeStepIndex,    setActiveStepIndex  ] = React.useState(0);
  const [currentMessage, setMessage] = useState("")
  const [currentUserEmail, setUserEmail] = useState("")
  const [deviceList, setDeviceList] = useState([])
  const [jwtToken, setJWTToken] = useState("")
  async function getUserEmail() {
    const user = await Auth.currentAuthenticatedUser();

   // console.log('attributes:', user.attributes.email);
    setUserEmail(user.attributes.email)

  }

  useEffect(()=>{

    Auth.currentSession().then(res=>{
      let jwttoken = res.getAccessToken().getJwtToken()
      setJWTToken(jwttoken)
     

    })

  },[])
  useEffect(()=>{
    getUserEmail()
   


  })
  const [DeviceInformation, setDeviceInformation] = useState({
      snowname:"",
      nodeid:"",
      instanceid:"",
      jobname:""

  })
  const handleSnowDeviceNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, snowname: e.detail.value})  };
  const handleNodeIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, nodeid: e.detail.value})
  };

  const handleInstanceIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, instanceid: e.detail.value})
  };

  const handleJobNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, jobname: e.detail.value})
  };
  return(
    <Container maxWidth={"false"} disableGutters >
                

<AppLayout
        headerSelector="#top-nav"
        navigation={<Navigation/>}
       content={
          <ContentLayout header={<Header variant="h1">Metric Dashboard</Header>}>
 <SpaceBetween direction="vertical" size="xs">
 
 <Container/> <Container/> <Container/> <Container/> <Container/>

<Container1>
  {/* <DeviceTable deviceList={deviceList}/> */}
  <iframe
   src={`https://grafana.kangest.people.aws.dev?auth_token=${jwtToken}`}
   width="1110"
   height="720"
   frameborder="0" 
  
  />
    {/* <iframe
   src="http://ec2-44-212-10-221.compute-1.amazonaws.com:3000/d-solo/UDdpyzz7z/prometheus-2-0-stats?orgId=1&refresh=1m&from=1689713956124&to=1689717556124&panelId=37"
   width="480"
   height="360"
   frameborder="0" 
  
  /> */}
</Container1>
</SpaceBetween>

          </ContentLayout>
        }
        
        />



    </Container>
  )
}

