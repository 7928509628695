import React, { Component, useEffect, useState } from "react"
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { Text } from "@aws-amplify/ui-react";
import { TopNavigation } from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { ExtensionRounded } from "@mui/icons-material";
import logo from "./awslogo.png";
import { useNavigate } from "react-router-dom";

    //console.log('attributes:', user.attributes.email);
    // setUserEmail(user.attributes.email)

export default function DeviceDashboard(){
const navigate = useNavigate()
const [email, setEmail] = useState()
const [name, setName] = useState()
useEffect(()=>{
  async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser();
    setEmail(user.attributes.email)
    setName(user.attributes.name)
   // console.log('attributes:', user.attributes);
  }

  getUserInfo()
},[])

const handleSignOut = async () => {
  try {
    await Auth.signOut()
    window.localStorage.removeItem('usertoken')
    navigate("/login")
    console.log('signed out')
  } catch (error) {
    console.log(error)
  }
}
//   constructor(props) {
//   super(props);
//   this.state = {};
// }


  return (
    <div className="awsui">
      <TopNavigation
        identity={{
          href: "/",
          title: "Snowbird",
          logo: {
            src: logo,
            alt: "AWS Logo",
          },
        }}
        utilities={[
          {
            type: "button",
            text: "Link",
            href: "",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)",
          },
          {
            type: "menu-dropdown",
            text: (email),
            description:(email),
            iconName: "user-profile",
            onItemClick:(item) => {if (item.detail.id == 'signout') {handleSignOut()}},
            items: [
              {
                id: "support-group",
                text: "Support",
                items: [
                  {
                    id: "documentation",
                    text: "Documentation",
                    href: "#",
                    external: true,
                    externalIconAriaLabel: " (opens in new tab)",
                  }
                ],
              },
              { id: "signout", text: "Sign out" },
            ],
          },
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu",
        }}
      />
    </div>
  );

}

// export default CustomHeader;



