import React, { Component, useEffect, useState } from "react"


// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Container1 from '@cloudscape-design/components/container'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Wizard} from "@awsui/components-react"
import { AppLayout, ContentLayout, Header, SpaceBetween, TopNavigation, Button, Input, FormField, ColumnLayout, Link, Modal} from "@cloudscape-design/components";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import Navigation from "../components/Navigation.js";
import DeviceTable from "../components/DeviceTable.js";



export default function DeviceDashboard(){
  /* These states store the device list, current user email, the selected device, and the Modals that display the state of certain operations
     e.g if the lambda function is deploying the package to snow etc
  */
  const navigate = useNavigate(); 
  const [    activeStepIndex,    setActiveStepIndex  ] = React.useState(0);
  const [currentMessage, setMessage] = useState("")
  const [currentUserEmail, setUserEmail] = useState("")
  const [deviceList, setDeviceList] = useState([])
  const [selectedDevice, setSelectedDevice] = useState("")
  const [modalVisible, setVisible] = useState(false)
  const [modalVisible2, setVisible2] = useState(false)
  const [modalVisible3, setVisible3] = useState(false)
  const [disabled, setDisabled] = useState(false)

  /* Gets the user email from Amplify Auth */
  async function getUserEmail() {
    const user = await Auth.currentAuthenticatedUser();

    setUserEmail(user.attributes.email)

  }
/* This function executes the lambda function that deploys the wifi performance package to the snow devices */
const executeSnowDevice = (body) => {
setDisabled(true)
setVisible2(true)
async function fetchAccessToken() {
  // const session = await Auth.currentSession();
  const currAuthUser = await Auth.currentAuthenticatedUser()

  const id_token = currAuthUser.signInUserSession.getIdToken().getJwtToken();
  // const jwt_token = session.getAccessToken().getJwtToken();
  fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/execute-snowapp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': id_token
      
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(data => {
      setDisabled(false)
     setVisible2(false)
      setVisible(true)
      //console.log(data)

     
    })

}
fetchAccessToken()



}
const runSnowDevice = () =>{
  try {
    var body = {
    
      device_name: selectedDevice[0].name,
      job_name: selectedDevice[0].jname,
      instance_id: selectedDevice[0].idnode,
      email_address: currentUserEmail
    
  }

    executeSnowDevice(body)
  
  } catch (error) {
    setVisible3(true)
   // console.log(error)
  }

 
  
  

}
  
  useEffect(()=>{
   
    async function fetchAccessToken() {
          const user = await Auth.currentAuthenticatedUser();
          setUserEmail(user.attributes.email)
      
          const currAuthUser = await Auth.currentAuthenticatedUser()
       
          const id_token = currAuthUser.signInUserSession.getIdToken().getJwtToken();
        
          fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/getDeviceInfo', {
            method: 'GET',
            headers: {
             
              'Content-Type': 'application/json',
              'Authorization': id_token,
              'email_address': user.attributes.email
            }
          })
            .then(response => response.json())
            .then(data => {
              // Process the response data
              var jsondata = JSON.parse(data.body)
               //console.log(jsondata)
              
              setDeviceList(jsondata)
              // console.log(JSON.parse(data.body));
            })
            .catch(error => {
              // Handle any errors
              console.error('Error:', error);
            });
  
    }

    fetchAccessToken()

//     fetch('https://kw3pfhvkoc.execute-api.us-east-1.amazonaws.com/beta/getDeviceInfo', {
//   method: 'GET',
//   headers: {
   
//     'Content-Type': 'application/json',
//     'Authorization': localStorage.getItem("usertoken"),
//     "email_address": currentUserEmail
//   }
// })
//   .then(response => response.json())
//   .then(data => {
//     // Process the response data
//     var jsondata = JSON.parse(data.body)
//     //console.log(jsondata)
//     setDeviceList(jsondata)
//     // console.log(JSON.parse(data.body));
//   })
//   .catch(error => {
//     // Handle any errors
//     console.error('Error:', error);
//   });
  },[])
  const [DeviceInformation, setDeviceInformation] = useState({
      snowname:"",
      nodeid:"",
      instanceid:"",
      jobname:""

  })
  const handleSnowDeviceNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, snowname: e.detail.value})  };
  const handleNodeIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, nodeid: e.detail.value})
  };

  const handleInstanceIDChange = (e) => {
    setDeviceInformation({...DeviceInformation, instanceid: e.detail.value})
  };

  const handleJobNameChange = (e) => {
    setDeviceInformation({...DeviceInformation, jobname: e.detail.value})
  };
  return(
    <Container maxWidth={"false"} disableGutters >
                  {/* <CustomHeader/> */}

<AppLayout
        headerSelector="#top-nav"
        navigation={<Navigation currentHref={'/device-dashboard'}/>}
       content={
          <ContentLayout header={<Header variant="h1">Device Dashboard</Header>}>
 <SpaceBetween direction="vertical" size="xs">
 
 <Container/> <Container/> <Container/> <Container/> <Container/>

<Container1>
<SpaceBetween direction="vertical" size="xxl">
  <DeviceTable deviceList={deviceList} setSelectedDevice={setSelectedDevice}/>
  <Button disabled={disabled} onClick={()=>{
    runSnowDevice()
  }}>
        Run Selected Device
       
  </Button>
  </SpaceBetween>
</Container1>
<Modal
      onDismiss={() => {setVisible(false); }}
      visible={modalVisible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link">Cancel</Button>
            <Button variant="primary" onClick={()=>{setVisible(false)}}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header="Run status"
    >
     Run Completed
    </Modal>
    <Modal
      onDismiss={() => {setVisible2(false); }}
      visible={modalVisible2}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link">Cancel</Button>
            <Button variant="primary" onClick={()=>{setVisible2(false)}}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header="Run status"
    >
    Deploying Snowcone Resources, please wait...
    </Modal>

    <Modal
      onDismiss={() => {setVisible3(false); }}
      visible={modalVisible3}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link">Cancel</Button>
            <Button variant="primary" onClick={()=>{setVisible3(false)}}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header="Run status"
    >
   Snow device hasn't been selected
    </Modal>

</SpaceBetween>

          </ContentLayout>
        }
        
        />



    </Container>
  )
}

